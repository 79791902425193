import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src477529347/src/autopatcher-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "permissions-in-autopatcher"
    }}>{`Permissions in AutoPatcher`}</h1>
    <p>{`AutoPatcher allows privileged Users to define very granular sets of permissions for themselves, other Users or API keys.
For example, you can limit the access to certain resources only, or narrow the permissions down only to some types of actions.`}</p>
    <p>{`Granting permissions is done by creating roles and then assigning the appropriate User or API key to one or more roles.`}</p>
    <p>{`Permissions can be browsed and managed in the `}<inlineCode parentName="p">{`Permissions`}</inlineCode>{` view.`}</p>
    <p><img alt="Permissions view" src={require("../assets/managing-permissions/permissions-view.png")} /></p>
    <h2 {...{
      "id": "managing-roles"
    }}>{`Managing Roles`}</h2>
    <p>{`A Role contains a definition of an access to certain resources and operations that can be performed on them.`}</p>
    <p><img alt="Permissions Roles" src={require("../assets/managing-permissions/permissions-roles.png")} /></p>
    <p>{`To create a new Role click the `}<inlineCode parentName="p">{`New Role`}</inlineCode>{` button.`}</p>
    <p><img alt="New Role" src={require("../assets/managing-permissions/new-role.png")} /></p>
    <p>{`To define permissions within a role, you need to create Rules. A Rule allows to define Resources, Actions and optionally Resource Ids that the Rule will apply to. The Role has all the permissions from all its Rules combined.`}</p>
    <p><img alt="Rule Resources" src={require("../assets/managing-permissions/rule-resources.png")} />{`
`}<img alt="Rule Actions" src={require("../assets/managing-permissions/rule-actions.png")} /></p>
    <p>{`After specifying the Rule (or multiple Rules), click `}<inlineCode parentName="p">{`Create`}</inlineCode>{` to complete.`}</p>
    <h3 {...{
      "id": "resource-to-action-mapping"
    }}>{`Resource to action mapping`}</h3>
    <p>{`The following table represents the relations between AutoPatcher resources and the actions
which are available for them.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Resource \\ Action`}</th>
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`get`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`list`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`create`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`update`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`delete`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`execute`}</inlineCode></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`action_link`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`activation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`api_key`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`role`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`role_binding`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`customer`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`data_report`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`event`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`installer_key`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`machine`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`notification_group`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pipeline`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`plan`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`report`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`update_log`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <p>{`The `}<inlineCode parentName="p">{`✓`}</inlineCode>{` in the particular cell of the table means that the action `}<em parentName="p">{`can`}</em>{` be performed on the resource,
whereas an empty cell means that the particular resource-to-action mapping has no effect on AutoPatcher's processing.`}</p>
    <p>{`Example:`}</p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`list`}</inlineCode>{` action for the `}<inlineCode parentName="li">{`machine`}</inlineCode>{` resource `}<strong parentName="li">{`is`}</strong>{` defined, which means that there is a logical use-case
for this: retrieving a list of machines.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`create`}</inlineCode>{` action for the `}<inlineCode parentName="li">{`update_log`}</inlineCode>{` resource `}<strong parentName="li">{`is not`}</strong>{` defined, since the update log is created
automatically as a result of the patching command executed on the machine and this process is not
controllable by the user.`}</li>
    </ul>
    <h2 {...{
      "id": "managing-assignments"
    }}>{`Managing Assignments`}</h2>
    <p>{`Assignments are bindings of Roles to Users or API keys.`}</p>
    <p>{`In order to create a new Assignment, click 'New Assignment' button.`}</p>
    <p><img alt="Permissions Assignments" src={require("../assets/managing-permissions/permissions-assignments.png")} /></p>
    <p>{`Choose an Assignment type - `}<inlineCode parentName="p">{`USER`}</inlineCode>{` or `}<inlineCode parentName="p">{`API_KEY`}</inlineCode>{`.`}</p>
    <ul>
      <li parentName="ul">{`for `}<inlineCode parentName="li">{`USER`}</inlineCode>{` type you need to provide email address`}</li>
      <li parentName="ul">{`for `}<inlineCode parentName="li">{`API_KEY`}</inlineCode>{` type you need to provide id of the API key`}</li>
    </ul>
    <p><img alt="Permissions New Assignment" src={require("../assets/managing-permissions/new-assignment.png")} /></p>
    <p>{`Choose a Role (or multiple Roles) from the ones available. Click 'Create' to complete.`}</p>
    <h2 {...{
      "id": "delay-of-the-permissions-changes"
    }}>{`Delay of the permissions changes`}</h2>
    <p>{`The important thing to remember is that the changes in the permissions made by the user don't
take effect immediately. Usually, it takes up to 1-2 minutes for the new permissions to apply
due to the asynchronous nature of the RBAC rules evaluation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      